/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { l as t, s as n } from "../chunks/vec32.js";
const r = new Float32Array(1);
function u(t) {
  --t;
  for (let n = 1; n < 32; n <<= 1) t |= t >> n;
  return t + 1;
}
function e(t, n, r) {
  return Math.min(Math.max(t, n), r);
}
function i(t, n) {
  return 0 === n ? 0 : Math.round(t / n) * n;
}
function a(t) {
  return !(t & t - 1);
}
function o(t) {
  return t--, t |= t >> 1, t |= t >> 2, t |= t >> 4, t |= t >> 8, t |= t >> 16, ++t;
}
function c(t) {
  return 10 ** Math.ceil(Math.LOG10E * Math.log(t));
}
function f(t, n, r) {
  return t + (n - t) * r;
}
function s(t, n, r, u, e) {
  return f(u, e, (t - n) / (r - n));
}
function h(t) {
  return t * Math.PI / 180;
}
function M(t) {
  return 180 * t / Math.PI;
}
function b(t, n = 1e-6) {
  return (t < 0 ? -1 : 1) / Math.max(Math.abs(t), n);
}
function m(t) {
  return Math.acos(e(t, -1, 1));
}
function l(t) {
  return Math.asin(e(t, -1, 1));
}
function F(t, n, r = 1e-6) {
  if (t === n) return !0;
  if (!Number.isFinite(t) || !Number.isFinite(n)) return !1;
  return (t > n ? t - n : n - t) <= r;
}
const N = new DataView(new ArrayBuffer(Float64Array.BYTES_PER_ELEMENT));
function g(t) {
  return N.setFloat64(0, t), N.getBigInt64(0);
}
function E(t) {
  return N.setBigInt64(0, t), N.getFloat64(0);
}
const B = BigInt("1000000"),
  I = w(1);
function w(t) {
  const n = g(t = Math.abs(t)),
    r = E(n <= B ? B : n - B);
  return Math.abs(t - r);
}
function x(t, n, r = I) {
  if (t === n) return !0;
  if (!Number.isFinite(t) || !Number.isFinite(n)) return !1;
  if (null != r) {
    if (w(Math.min(Math.abs(t), Math.abs(n))) < r) return Math.abs(t - n) <= r;
  }
  const u = g(t),
    e = g(n);
  if (u < 0 != e < 0) return !1;
  return !((u < e ? e - u : u - e) > B);
}
function y(t, n, r = 1e-6) {
  if (t === n) return !0;
  if (!Number.isFinite(t) || !Number.isFinite(n)) return !1;
  const u = Math.abs(t - n),
    e = Math.abs(t),
    i = Math.abs(n);
  if (0 === t || 0 === n || e < 1e-12 && i < 1e-12) {
    if (u > .01 * r) return !1;
  } else if (u / (e + i) > r) return !1;
  return !0;
}
function A(t) {
  return P(Math.max(-j, Math.min(t, j)));
}
function P(t) {
  return r[0] = t, r[0];
}
function p(t, n, r) {
  const u = e((r - t) / (n - t), 0, 1);
  return u * u * (3 - 2 * u);
}
function L(r, u) {
  const e = t(r),
    i = l(r[2] / e),
    a = Math.atan2(r[1] / e, r[0] / e);
  return n(u, e, i, a), u;
}
function T(t, r) {
  const u = t[0],
    e = t[1],
    i = t[2],
    a = Math.cos(e);
  n(r, u * a * Math.cos(i), u * a * Math.sin(i), u * Math.sin(e));
}
function _(t) {
  const n = t[0] * t[0] + t[1] * t[1] + t[2] * t[2],
    r = t[3] * t[3] + t[4] * t[4] + t[5] * t[5],
    u = t[6] * t[6] + t[7] * t[7] + t[8] * t[8];
  return !(F(n, 1) && F(r, 1) && F(u, 1));
}
function d(t, n) {
  return (t % n + n) % n;
}
const j = P(34028234663852886e22);
export { m as acosClamped, l as asinClamped, L as cartesianToSpherical, e as clamp, A as clampFloat32, h as deg2rad, F as floatEqualAbsolute, y as floatEqualRelative, x as floatEqualUlp, _ as hasScaling, a as isPowerOfTwo, f as lerp, d as moduloPositive, c as nextHighestPowerOfTen, u as nextHighestPowerOfTwo, o as nextPowerOfTwo, j as numberMaxFloat32, M as rad2deg, b as reciprocalClamped, i as roundToNearest, s as scale, p as smoothstep, T as sphericalToCartesian };